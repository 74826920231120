import revive_payload_client_Hy5UDwekdV from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_huAdMacoyx from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aXB60GVQi6 from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZLEBqVj0Ql from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_n8vn6AO4AI from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RchF8hWjC7 from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nIs6BUHrXN from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_bSM6vok4jI from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.10.0_@parcel+watcher@2.4.1_@types+node@20.14.10_drizzle-orm@0.33_wsh7flzoxrsvw7kn2bes4xr2fi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_vtn8Vplzje from "/app/node_modules/.pnpm/@sentry+nuxt@8.32.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._hqf4crf6flvea4kb7y4blcw2t4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import session_client_VjBPfMjyju from "/app/node_modules/.pnpm/nuxt-auth-utils@0.3.5_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_MuecbPRqkY from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_magicast@0.3.4_rollup@4.18.1_vite@5.4.2_@types+node@20.14.10_sass_kdekp7hhs7irqkmgmapuftvtbi/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_EhCaQCKCgd from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_magicast@0.3.4_rollup@4.18.1_vite@5.4.2_@types+node@20.14.10_sass_kdekp7hhs7irqkmgmapuftvtbi/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_vjHVKuivuF from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_magicast@0.3.4_rollup@4.18.1_vite@5.4.2_@types+node@20.14.10_sass_kdekp7hhs7irqkmgmapuftvtbi/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ZJfltoGOVd from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_IdbXYDZmEx from "/app/node_modules/.pnpm/@nuxt+icon@1.4.3_magicast@0.3.4_rollup@4.18.1_vite@5.4.2_@types+node@20.14.10_sass-embedded@1_6opjxrtmyhmj5qje6brlqlbkia/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_o9IEgU9LwG from "/app/plugins/posthog.client.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_Hy5UDwekdV,
  unhead_huAdMacoyx,
  router_aXB60GVQi6,
  payload_client_ZLEBqVj0Ql,
  navigation_repaint_client_n8vn6AO4AI,
  check_outdated_build_client_RchF8hWjC7,
  chunk_reload_client_nIs6BUHrXN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bSM6vok4jI,
  sentry_client_vtn8Vplzje,
  sentry_client_config_o34nk2sJbg,
  session_client_VjBPfMjyju,
  slideovers_MuecbPRqkY,
  modals_EhCaQCKCgd,
  colors_vjHVKuivuF,
  plugin_client_ZJfltoGOVd,
  plugin_IdbXYDZmEx,
  posthog_client_o9IEgU9LwG,
  sentry_client_shVUlIjFLk
]