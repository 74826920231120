import { default as indexrsmoklGtOeMeta } from "/app/pages/admin/index/index.vue?macro=true";
import { default as organizationsvfGUedfbRTMeta } from "/app/pages/admin/index/organizations.vue?macro=true";
import { default as transactionsJS1aTQrLvzMeta } from "/app/pages/admin/index/transactions.vue?macro=true";
import { default as waitlistAkg3sEyrPuMeta } from "/app/pages/admin/index/waitlist.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as forgot_45passwordPFs5wuK5FuMeta } from "/app/pages/auth/forgot-password.vue?macro=true";
import { default as login_45simple_45passwordMVO9cFyIVLMeta } from "/app/pages/auth/login-simple-password.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as reset_45password5ABZjlJW61Meta } from "/app/pages/auth/reset-password.vue?macro=true";
import { default as verify_45otpkESLVeCBuFMeta } from "/app/pages/auth/verify-otp.vue?macro=true";
import { default as emailxwOD1Qz5wuMeta } from "/app/pages/dashboard/index/agent/index/email.vue?macro=true";
import { default as indexh5qd4DfmNTMeta } from "/app/pages/dashboard/index/agent/index/index.vue?macro=true";
import { default as socialfWjnfbVXTOMeta } from "/app/pages/dashboard/index/agent/index/social.vue?macro=true";
import { default as indexnQCY7GaBQCMeta } from "/app/pages/dashboard/index/agent/index.vue?macro=true";
import { default as index5uAp7opK7wMeta } from "/app/pages/dashboard/index/campaign/index.vue?macro=true";
import { default as index0uCvlWIN5LMeta } from "/app/pages/dashboard/index/contacts/index.vue?macro=true";
import { default as indexh2EvuEjCwKMeta } from "/app/pages/dashboard/index/image-gallery/index.vue?macro=true";
import { default as index21dqhbXERPMeta } from "/app/pages/dashboard/index/import/index.vue?macro=true";
import { default as index5duQQqgpHgMeta } from "/app/pages/dashboard/index/index.vue?macro=true";
import { default as indexR7cYoaAr3FMeta } from "/app/pages/dashboard/index/onboarding/index.vue?macro=true";
import { default as indexttEaFXe3D5Meta } from "/app/pages/dashboard/index/pipeline/index.vue?macro=true";
import { default as indexnXGBTBxBwUMeta } from "/app/pages/dashboard/index/posts/index.vue?macro=true";
import { default as indexFLTb1BAS0kMeta } from "/app/pages/dashboard/index/richard/index.vue?macro=true";
import { default as billingOrOkWh8oozMeta } from "/app/pages/dashboard/index/settings/index/billing.vue?macro=true";
import { default as indexZ0Pem49jGLMeta } from "/app/pages/dashboard/index/settings/index/index.vue?macro=true";
import { default as indexDX3w3YhzmvMeta } from "/app/pages/dashboard/index/settings/index.vue?macro=true";
import { default as indexJyhFJ6Zf96Meta } from "/app/pages/dashboard/index/tasks/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: indexi8nCATZISJMeta?.name,
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-index",
    path: "",
    component: () => import("/app/pages/admin/index/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-index-organizations",
    path: "organizations",
    component: () => import("/app/pages/admin/index/organizations.vue").then(m => m.default || m)
  },
  {
    name: "admin-index-transactions",
    path: "transactions",
    component: () => import("/app/pages/admin/index/transactions.vue").then(m => m.default || m)
  },
  {
    name: "admin-index-waitlist",
    path: "waitlist",
    component: () => import("/app/pages/admin/index/waitlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login-simple-password",
    path: "/auth/login-simple-password",
    component: () => import("/app/pages/auth/login-simple-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45password5ABZjlJW61Meta || {},
    component: () => import("/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-otp",
    path: "/auth/verify-otp",
    meta: verify_45otpkESLVeCBuFMeta || {},
    component: () => import("/app/pages/auth/verify-otp.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name,
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexnQCY7GaBQCMeta?.name,
    path: "agent",
    component: () => import("/app/pages/dashboard/index/agent/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-index-agent-index-email",
    path: "email",
    component: () => import("/app/pages/dashboard/index/agent/index/email.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-agent-index",
    path: "",
    component: () => import("/app/pages/dashboard/index/agent/index/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-agent-index-social",
    path: "social",
    component: () => import("/app/pages/dashboard/index/agent/index/social.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-index-campaign",
    path: "campaign",
    component: () => import("/app/pages/dashboard/index/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-contacts",
    path: "contacts",
    component: () => import("/app/pages/dashboard/index/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-image-gallery",
    path: "image-gallery",
    component: () => import("/app/pages/dashboard/index/image-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-import",
    path: "import",
    component: () => import("/app/pages/dashboard/index/import/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index",
    path: "",
    component: () => import("/app/pages/dashboard/index/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-onboarding",
    path: "onboarding",
    component: () => import("/app/pages/dashboard/index/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-pipeline",
    path: "pipeline",
    component: () => import("/app/pages/dashboard/index/pipeline/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-posts",
    path: "posts",
    component: () => import("/app/pages/dashboard/index/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-richard",
    path: "richard",
    component: () => import("/app/pages/dashboard/index/richard/index.vue").then(m => m.default || m)
  },
  {
    name: indexDX3w3YhzmvMeta?.name,
    path: "settings",
    component: () => import("/app/pages/dashboard/index/settings/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-index-settings-index-billing",
    path: "billing",
    component: () => import("/app/pages/dashboard/index/settings/index/billing.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-index-settings-index",
    path: "",
    component: () => import("/app/pages/dashboard/index/settings/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-index-tasks",
    path: "tasks",
    component: () => import("/app/pages/dashboard/index/tasks/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]