export default defineAppConfig({
  ui: {
    primary: "teal",
    gray: "neutral",
    button: {
      default: { loadingIcon: "i-ph-spinner" },
    },
  },
  seo: {
    title: "Markster - Turbocharging sales with one integrated platform",
    description:
      "Markster is the all-in-one AI-powered business operating system that streamlines operations, automates tasks, and drives growth for businesses of all sizes - from solopreneurs to large enterprises with B2B models.",
  },
});
